import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from "@angular/core"
import { NgModel } from "@angular/forms"
import { Subscription } from "rxjs"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { Router, ActivatedRoute } from "@angular/router"
import {
    GrupoUsuario,
    GrupoUsuarios,
    GrupoUsuarioUsuario,
    GrupoUsuarioUsuarios,
    Usuario,
    Usuarios
} from "@puntaje/puntaje/api-services"
import { DomSanitizer } from "@angular/platform-browser"
import {
    StateService,
    PaginatorComponent,
    ToggleExpandableDirective,
    SessionService,
    GenericModalComponent
} from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { SimpleModalService } from "@puntaje/shared/layouts"

@Component({
    selector: "grupo-usuarios-asociados",
    templateUrl: "grupo_usuarios_asociados.component.html",
    styleUrls: ["grupo_usuarios_asociados.component.scss"]
})
export class GrupoUsuariosAsociadosComponent implements OnInit {
    pais: string = config.plataforma.pais
    idPais: string[] = config.plataforma.identificadorUsuario
    aliases = config.plataforma?.identificadorUsuarioAlias
    nombreId: string = this.idPais.map(id => this.aliases?.[id] || id).join("/")

    grupoUsuarios: GrupoUsuario[]
    @ViewChild("paginator") paginator: PaginatorComponent
    @ViewChild("idValueAlumno") idValueAlumno: NgModel
    @ViewChild("idValueDocente") idValueDocente: NgModel
    @ViewChild("buttonGrupoUsuarioManager", { read: ToggleExpandableDirective, static: true })
    buttonGrupoUsuarioManager: ToggleExpandableDirective

    estudiantesOpen: boolean = false
    docentesOpen: boolean = false
    allStudents: any
    allTeachers: any

    addGrupoUsuarioOpen: boolean = false
    private sub: Subscription
    grupoUsuario: GrupoUsuario
    grupoUsuarioUsuarios: GrupoUsuarioUsuario
    usuario: Usuario
    usuarioLoaded: boolean = false

    usuarios: Usuario[]

    addEstudianteOpen: boolean = false
    addDocenteOpen: boolean = false

    usuarioInGrupo: boolean = false

    idValue: string
    nombre: string
    apellidoPaterno: string
    apellidoMaterno: string
    email: string

    clasificaciones: Clasificacion[]
    nivelesConfig: any
    newGrupoUsuario: GrupoUsuario = new GrupoUsuario()
    grupoUsuarioToEdit: GrupoUsuario

    fechaInicialPeriodo: Date
    fechaFinalPeriodo: Date

    nombreVacio: boolean = false
    cursoNoSeleccionado: boolean = false
    bloqueoBotonGrupo: boolean = false
    config: typeof config = config

    hasRol: { [rol: string]: boolean }
    nombrePropietario: string
    propietario: number
    openModal = new EventEmitter()
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent

    openModalBorrar = new EventEmitter()
    @ViewChild("genericModalBorrar", { read: GenericModalComponent }) genericModalBorrar: GenericModalComponent

    openModalEditar = new EventEmitter()
    @ViewChild("genericModalEditar", { read: GenericModalComponent }) genericModalEditar: GenericModalComponent

    constructor(
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected grupoUsuarioUsuariosService: GrupoUsuarioUsuarios,
        protected router: Router,
        protected route: ActivatedRoute,
        protected simpleModalService: SimpleModalService,
        protected clasificacionesService: Clasificaciones,
        protected sessionService: SessionService
    ) {}

    _establecimientoId: number
    @Input() showVaciar: boolean = true
    @Input() evaluacionTipo: string
    @Input()
    get establecimientoId() {
        return this._establecimientoId
    }

    set establecimientoId(establecimientoId) {
        this._establecimientoId = establecimientoId
        establecimientoId && this.fechaInicialPeriodo && this.fechaFinalPeriodo && this.getGrupoUsuarios()
    }

    ngOnInit() {
        const roles = this.sessionService.getRoles()
        this.hasRol = roles.reduce((acc, rol) => {
            acc[rol] = true

            return acc
        }, {})

        if (config.plataforma.name == "Aprendolibre" && config.app.name == "profesores") {
            this.showVaciar = false
        }
        this.sub = this.route.queryParams.subscribe(query => {
            this.evaluacionTipo = query["evaluacion_tipo"]
        })
        if (!this.evaluacionTipo) {
            this.evaluacionTipo = config.plataforma.evaluacionDefault
        }
        this.nivelesConfig = config.evaluaciones[this.evaluacionTipo].cursos
            ? config.evaluaciones[this.evaluacionTipo].cursos.map(nivel => nivel.clasificacion)
            : ""

        let params: any = {
            clasificacion: { clasificacion: this.nivelesConfig },
            sort_by: "id",
            order: "ASC"
        }

        if (config.evaluaciones[this.evaluacionTipo].cursoTipo) {
            params.clasificacion_tipo = {
                clasificacion_tipo: config.evaluaciones[this.evaluacionTipo].cursoTipo
            }
        }

        this.clasificacionesService.where(params).then((clasificaciones: Clasificacion[]) => {
            this.clasificaciones = clasificaciones
        })
    }

    actualizarGrupoUsuarios() {
        this.getGrupoUsuarios()
        if (this.grupoUsuario) {
            this.paginator.reload()
        }
    }

    getGrupoUsuarios() {
        let params: any = {
            grupo_usuario: {
                establecimiento_id: this.establecimientoId
            },
            fecha_inicial: this.fechaInicialPeriodo,
            fecha_final: this.fechaFinalPeriodo,
            utp_establecimiento: 1,
            utp_asociacion: 1,
            sort: [{ field: "nombre", criteria: "asc" }],
            render_options: {
                methods: ["numero_alumnos", "numero_propietarios"],
                include: { grupo_usuario_usuarios: null }
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.grupoUsuarios = grupoUsuarios
            let ids: number[] = this.grupoUsuarios.map(g => g.id)
            this.getAllStudents(ids)
            this.getAllTeachers(ids)
        })
    }

    getAllStudents(ids: number[]) {
        this.grupoUsuariosService.numeroAlumnos(ids, true).then((data: object) => {
            this.allStudents = data
        })
    }

    getAllTeachers(ids: number[]) {
        this.grupoUsuariosService.numeroDocentes(ids, true).then((data: object) => {
            this.allTeachers = data
        })
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicialPeriodo = fechaInicial
        this.fechaFinalPeriodo = fechaFinal

        this.getGrupoUsuarios()
    }

    onSelectCurso(value) {}

    editarGrupoUsuario(grupoUsuario: GrupoUsuario) {
        this.grupoUsuarioToEdit = grupoUsuario
        this.openModalEditar.emit()
    }

    createGrupoUsuario(grupoUsuario: GrupoUsuario) {
        grupoUsuario.establecimiento_id = this.establecimientoId

        this.grupoUsuariosService.save(grupoUsuario).then((gu: GrupoUsuario) => {
            this.getGrupoUsuarios()
            this.newGrupoUsuario = new GrupoUsuario()

            this.buttonGrupoUsuarioManager.forceClose()
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader("Aviso")
            this.simpleModalService.setModalStringContent("Curso creado exitosamente.")
            this.simpleModalService.showSimpleModal()
        })
    }

    updateGrupoUsuario(grupoUsuario: GrupoUsuario) {
        this.grupoUsuariosService.update(grupoUsuario.id, grupoUsuario).then((gu: GrupoUsuario) => {
            this.genericModalEditar.close()
            this.grupoUsuarioToEdit = null
            this.getGrupoUsuarios()
        })
    }

    cancelCrearGrupoUsuario() {
        this.buttonGrupoUsuarioManager.forceClose()
    }

    cancelEditarGrupoUsuario() {
        this.genericModalEditar.close()
    }

    deleteGrupoUsuario(grupoUsuario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro de querer borrar a " + grupoUsuario.nombre + " del establecimiento?"
        )
        this.simpleModalService.setModalCallback(() => this.confirmDeleteGrupoUsuario(grupoUsuario))
        this.simpleModalService.showSimpleModal()
    }

    confirmDeleteGrupoUsuario(grupoUsuario) {
        this.usuariosService
            .where({ per: 1, grupo_usuario_usuario: { grupo_usuario_id: grupoUsuario.id, propietario: [0, 1] } })
            .then((usuarios: Usuario[]) => {
                if (usuarios[0]) {
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Advertencia")
                    this.simpleModalService.setModalStringContent(
                        "El grupo aún posee estudiantes y/o docentes. Elimínelos antes de borrar el grupo."
                    )
                    this.simpleModalService.showSimpleModal()
                    //return alert("El grupo aún posee estudiantes y/o docentes. Elimínelos antes de borrar el grupo.");
                } else {
                    this.grupoUsuariosService.remove(grupoUsuario.id).then(r => {
                        this.getGrupoUsuarios()
                    })
                }
            })
    }

    vaciarGrupoUsuario(grupoUsuario, propietario = null) {
        let message = "¿Está seguro de querer vaciar a '" + grupoUsuario.nombre + "' del establecimiento?"
        if (propietario !== null || propietario !== undefined) {
            message =
                "¿Está seguro de querer vaciar " +
                (propietario ? "docentes" : "estudiantes") +
                " a '" +
                grupoUsuario.nombre +
                "' del establecimiento?"
        }

        this.simpleModalService.cleanModal()
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(message)
        this.simpleModalService.setModalCallback(() => this.confirmVaciarGrupoUsuario(grupoUsuario, propietario))
        this.simpleModalService.showSimpleModal()
    }

    confirmVaciarGrupoUsuario(grupoUsuario: GrupoUsuario, propietario = null) {
        let message = grupoUsuario.nombre + "ha sido vaciado con éxito."
        let grupoUsuarioParams = {}
        if (propietario !== null || propietario !== undefined) {
            message =
                "Los " +
                (propietario ? "docentes" : "estudiantes") +
                " de '" +
                grupoUsuario.nombre +
                "' han sido vaciados con éxito."

            grupoUsuarioParams["propietario"] = propietario ? 1 : 0
        }

        this.simpleModalService.closeSimpleModal()
        this.grupoUsuariosService.vaciar(grupoUsuario.id, grupoUsuarioParams).then(() => {
            if (propietario == 1 || propietario == null) {
                grupoUsuario.numero_propietarios = 0
            }
            if (propietario == 0 || propietario == null) {
                grupoUsuario.numero_alumnos = 0
            }
            this.updateCountAllUsuarios(propietario)

            this.docentesOpen = false
            this.estudiantesOpen = false
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader("Aviso")
            this.simpleModalService.setModalStringContent(message)
            this.simpleModalService.showSimpleModal()
        })
    }

    verEstudiantes(grupoUsuario: GrupoUsuario) {
        if (
            (!this.estudiantesOpen && (!this.grupoUsuario || grupoUsuario.id == this.grupoUsuario.id)) ||
            !this.grupoUsuario ||
            grupoUsuario.id != this.grupoUsuario.id
        ) {
            this.usuarios = null
            this.estudiantesOpen = false
        }

        this.grupoUsuario = grupoUsuario
        this.docentesOpen = this.addEstudianteOpen = this.addDocenteOpen = false
        setTimeout(() => (this.estudiantesOpen = true), 1)
    }

    verDocentes(grupoUsuario: GrupoUsuario) {
        if (
            (!this.docentesOpen && (!this.grupoUsuario || grupoUsuario.id == this.grupoUsuario.id)) ||
            !this.grupoUsuario ||
            grupoUsuario.id != this.grupoUsuario.id
        ) {
            this.usuarios = null
            this.docentesOpen = false
        }

        this.grupoUsuario = grupoUsuario
        this.estudiantesOpen = this.addEstudianteOpen = this.addDocenteOpen = false
        setTimeout(() => (this.docentesOpen = true), 1)
    }

    verUsuariosFun(grupoUsuarioId, propietario) {
        return (page: number = 1, per: number = 10) => {
            return this.usuariosService
                .where({
                    sort_by: "apellido_paterno",
                    page: page,
                    per: per,
                    grupo_usuario_usuario: { grupo_usuario_id: grupoUsuarioId, propietario: propietario },
                    methods: "fecha_ultimo_login"
                })
                .then((usuarios: Usuario[], total: number) => {
                    this.usuarios = usuarios
                    this.usuarios.forEach(usuario => {
                        if (!usuario["usuario_" + this.pais]) {
                            usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
                        }
                        ;(usuario as any).identificadorPais = this.idPais.find(
                            idpais => !!usuario["usuario_" + this.pais][idpais.toLowerCase()]
                        )
                    })
                    return total
                })
        }
    }

    showAddEstudiante() {
        this.addEstudianteOpen = true
        //this.addDocenteOpen = false;
    }

    showAddDocente() {
        this.addDocenteOpen = true
        //this.addEstudianteOpen = false;
    }

    getUsuarioFun(idValue, propietario = null) {
        let params = { grupo_usuario: { id: this.grupoUsuario.id } }
        if (propietario != null) {
            params["grupo_usuario_usuario"] = { propietario: propietario }
        }
        params["usuario_" + this.pais] = {}
        params["usuario_" + this.pais][this.idPais.join("_o_").toLowerCase()] = idValue

        return this.usuariosService.where(params).then((usuarios: Usuario[]) => {
            delete params["grupo_usuario"]
            delete params["grupo_usuario_usuario"]
            if (usuarios[0]) {
                this.usuarioInGrupo = true
                // dirty stuff: si no viene propietario, es porque lo estoy usando en la edicion de usuario,
                // y necesito el usuario que va a buscar
                if (!propietario) this.usuario = usuarios[0]

                return null
            } else {
                this.usuarioInGrupo = false

                return this.usuariosService.where(params).then((usuarios: Usuario[]) => {
                    this.usuarioLoaded = true
                    this.usuario = usuarios[0]
                })
            }
        })
    }

    getUsuario(propietario) {
        this.usuarioLoaded = false

        this.nombre = ""
        this.apellidoPaterno = ""
        this.apellidoMaterno = ""
        this.email = ""

        this.getUsuarioFun(this.idValue, propietario)
    }

    deleteUsuarioFromGroup(usuario, propietario) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro de eliminar a " + usuario.nombreCompleto() + " de " + this.grupoUsuario.nombre + "?"
        )
        this.simpleModalService.setModalCallback(() => this.confirmDeleteUsuarioFromGroup(usuario, propietario))
        this.simpleModalService.showSimpleModal()
    }

    confirmDeleteUsuarioFromGroup(usuario, propietario) {
        this.grupoUsuarioUsuariosService
            .where({
                grupo_usuario_usuario: {
                    usuario_id: usuario.id,
                    grupo_usuario_id: this.grupoUsuario.id,
                    propietario: propietario
                }
            })
            .then((guus: GrupoUsuarioUsuario[]) => {
                guus.forEach(guu => {
                    this.grupoUsuarioUsuariosService.remove(guu.id).then(r => {
                        this.paginator.reload()
                    })
                })
            })
    }

    resetPassword(u) {
        this.simpleModalService.setModalHeader("Confirmación")
        this.simpleModalService.setModalStringContent(
            "¿Está seguro que desea restablecer la contraseña de " + u.nombreCompleto() + "?"
        )
        this.simpleModalService.setModalCallback(() => this.confirmResetPasssword(u))
        this.simpleModalService.showSimpleModal()
    }

    resetAllPasswords(docente = false) {
        this.simpleModalService.setModalHeader("Confirmación")
        const userType = docente ? "docentes" : "estudiantes"
        this.simpleModalService.setModalStringContent(
            `¿Está seguro que desea restablecer todas las contraseñas de los ${userType}?`
        )
        this.simpleModalService.setModalCallback(() => this.confirmResetAllPassswords(docente))
        this.simpleModalService.showSimpleModal()
    }

    confirmResetPasssword(u) {
        let usuario = new Usuario()
        delete usuario.usuario_chile
        delete usuario.usuario_colombia
        delete usuario.usuario_mexico

        let pass: string

        for (let i = 0; i < this.idPais.length; i++) {
            let idPais = u["usuario_" + this.pais][this.idPais[i].toLowerCase()]
            if (idPais) {
                pass = idPais.substring(0, 6)
                break
            }
        }

        usuario.password = pass
        usuario.password_confirmation = pass
        usuario.id = u.id
        usuario.manual_reset_password = true

        this.usuariosService.update(u.id, usuario).then(u => {
            this.simpleModalService.setModalHeader("Aviso")
            this.simpleModalService.setModalStringContent(
                "La contraseña ha sido restablecida exitosamente a los primeros 6 dígitos del " + this.nombreId
            )
            this.simpleModalService.showSimpleModal()
        })
    }

    confirmResetAllPassswords(docente = false) {
        const ids = []
        this.usuarios.forEach(u => {
            ids.push(u.id)
        })
        this.usuariosService.resetMassivePasswords(this.grupoUsuario.id, docente).then(count => {
            this.simpleModalService.setModalHeader("Aviso")
            this.simpleModalService.setModalStringContent(
                "Las contraseñas han sido restablecidas exitosamente a los primeros 6 dígitos del " + this.nombreId
            )
            this.simpleModalService.showSimpleModal()
        })
    }

    editUsuario(usuario, idValueControl: NgModel = null) {
        let idValue = usuario["usuario_" + this.pais][this.idPais[0].toLowerCase()]

        this.getUsuarioFun(idValue).then(() => {
            if (idValueControl) {
                idValueControl.control.markAsDirty()
                idValueControl.control.markAsTouched()
            }

            if (!(!this.validateIdValueUsuario(usuario).valid || (this.usuario && this.usuario.id != usuario.id))) {
                this.usuariosService.update(usuario.id, usuario).then((usuario: Usuario) => {
                    this.idValue = ""
                    this.usuarioLoaded = false
                    this.usuarioInGrupo = false
                    this.addDocenteOpen = this.addEstudianteOpen = false
                    this.usuario = null
                    this.paginator.reload()
                })
                this.simpleModalService.cleanModal()
                this.simpleModalService.setModalHeader("Aviso")
                this.simpleModalService.setModalStringContent("Los datos del usuario fueron modificados con éxito.")
                this.simpleModalService.showSimpleModal()
            }
        })
    }

    validateIdValueFun(idValue) {
        if (!idValue) idValue = ""
        switch (this.pais) {
            case "chile":
                return { valid: this.validaRut(idValue), msg: "El rut ingresado no es válido." }
            case "colombia":
                return {
                    valid: idValue.length > 8 && idValue.length <= 11 && /^[1-9][0-9]+$/.test(idValue),
                    msg: "El número de identificación no es válido."
                }
            case "mexico":
                return {
                    valid: this.curpValida(idValue),
                    msg: "El CURP ingresado no es válido."
                }
            default:
                return { valid: false, msg: "País de la plataforma no disponible" }
        }
    }

    validateIdValue() {
        return this.validateIdValueFun(this.idValue)
    }

    validateIdValueUsuario(usuario) {
        let idValue = usuario["usuario_" + this.pais][this.idPais[0].toLowerCase()]

        return this.validateIdValueFun(idValue)
    }

    validaRut(rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false
        var tmp = rutCompleto.split("-")
        var digv = tmp[1]
        var rut = tmp[0]
        if (digv == "K") digv = "k"
        return this.dv(rut) == digv
    }

    dv(T) {
        var M = 0,
            S = 1
        for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
        return S ? S - 1 : "k"
    }

    //Función para validar una CURP
    curpValida(curpMinusculas) {
        const curp = curpMinusculas.toUpperCase()
        var re =
                /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
            validado = curp.match(re)

        if (!validado) {
            //Coincide con el formato general?
            return false
        }

        //Validar que coincida el dígito verificador
        function digitoVerificador(curp17) {
            //Fuente https://consultas.curp.gob.mx/CurpSP/
            var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
                lngSuma = 0.0,
                lngDigito = 0.0
            for (var i = 0; i < 17; i++) {
                lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i)
            }
            lngDigito = 10 - (lngSuma % 10)
            if (lngDigito == 10) return 0
            return lngDigito
        }

        if (validado[2] != digitoVerificador(validado[1])) {
            return false
        }

        return true //Validado
    }

    finishAsociarUsuario(perfil: string, event) {
        if (perfil == "estudiante") {
            this.addEstudianteOpen = event.addUsuarioOpen
            this.grupoUsuario.numero_alumnos += 1
        } else {
            this.addDocenteOpen = event.addUsuarioOpen
            this.grupoUsuario.numero_propietarios += 1
        }
        this.updateCountAllUsuarios(perfil == "estudiante" ? 0 : 1)

        this.paginator.reload()
    }

    openVaciarTodos(propietario: number) {
        const propietarioToString = {
            0: "estudiantes",
            1: "profesores"
        }

        this.nombrePropietario = propietarioToString[propietario]
        this.propietario = propietario

        this.openModal.emit()
    }

    vaciarTodos(vaciarString: string) {
        if (vaciarString == "VACIAR") {
            this.genericModal.close()

            const params = {
                fecha_inicial: this.fechaInicialPeriodo,
                fecha_final: this.fechaFinalPeriodo,
                propietario: this.propietario,
                establecimiento_id: this.establecimientoId
            }

            this.grupoUsuariosService.vaciarAll(params).then(response => {
                this.resetNumeroUsuarios(this.propietario)

                this.docentesOpen = false
                this.estudiantesOpen = false

                this.simpleModalService.cleanModal()
                this.simpleModalService.setModalHeader("Aviso")
                this.simpleModalService.setModalStringContent("Cursos vaciados con éxito")
                this.simpleModalService.showSimpleModal()
            })
        }
    }

    openBorrarTodos() {
        const hasIntegrantes = this.grupoUsuarios.some(gu => gu.numero_alumnos > 0 || gu.numero_propietarios > 0)

        if (hasIntegrantes) {
            this.simpleModalService.cleanModal()
            this.simpleModalService.setModalHeader("Advertencia")
            this.simpleModalService.setModalStringContent(
                "Los cursos no se encuentran vacíos. Para borrarlos primero vacíe cada curso."
            )
            this.simpleModalService.showSimpleModal()

            return
        }

        this.openModalBorrar.emit()
    }

    borrarTodos(borrarString: string) {
        if (borrarString == "BORRAR") {
            this.genericModalBorrar.close()

            const params = {
                establecimiento_id: this.establecimientoId,
                fecha_inicial: this.fechaInicialPeriodo,
                fecha_final: this.fechaFinalPeriodo
            }

            this.grupoUsuariosService
                .destroyAllRange(params)
                .then(resp => {
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Aviso")
                    this.simpleModalService.setModalStringContent("Cursos borrados con éxito")
                    this.simpleModalService.showSimpleModal()

                    this.grupoUsuarios = []
                    this.docentesOpen = this.estudiantesOpen = false
                    this.resetNumeroUsuarios()
                })
                .catch(resp => {
                    if (resp.success == false && resp.message) {
                        this.simpleModalService.cleanModal()
                        this.simpleModalService.setModalHeader("Error")
                        this.simpleModalService.setModalStringContent(resp.message)
                        this.simpleModalService.showSimpleModal()
                    }
                })
        }
    }

    updateCountAllUsuarios(propietario = null) {
        if (propietario == 1 || propietario == null) {
            this.allTeachers = this.grupoUsuarios.reduce((acc, gu) => {
                return acc + gu.numero_propietarios
            }, 0)
        }
        if (propietario == 0 || propietario == null) {
            this.allStudents = this.grupoUsuarios.reduce((acc, gu) => {
                return acc + gu.numero_alumnos
            }, 0)
        }
    }

    private resetNumeroUsuarios(propietario = null) {
        if (propietario == 1) {
            this.grupoUsuarios.forEach(gu => {
                gu.numero_propietarios = 0
            })
            this.allTeachers = 0
        } else if (propietario == 0) {
            this.grupoUsuarios.forEach(gu => {
                gu.numero_alumnos = 0
            })
            this.allStudents = 0
        } else {
            this.allTeachers = 0
            this.allStudents = 0
        }
    }
}
